import React, { useEffect } from 'react'
import packageJson from '../../package.json'
import NavLink from '../components/NavLink'
import Footer from '../components/Footer';
import { Bundle, CartIcon, BundleLine, BundleSelect, BundleText, BundleTitle, BundleWrapper, ClassesTitle, ClassesWrapper, ECours, Banner, EcoursText, ECoursWrapper, PricingWrapper, PricingItem, PricingTitle, PricingButtom } from '../components/Classes/ClassesElements';
import classes from '../data/classes'
import Nav from '../components/Nav';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { addToCart } from '../actions/shoppingActions';
import { toast } from 'react-toastify';

const Classes = (props) => {
  const caching = () => {
    let version = localStorage.getItem('version');
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }

  const toastProps = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "toast-message"
  }
  const notifyItemAdded = (id) => {
    let existItem = props.addedItems.find(item => item.id === id)
    if (existItem) {
      toast('Article déjà au panier!', {
        ...toastProps
      })      
    }
    toast('Item ajouté au panier!', {
      ...toastProps
    })
  }
  useEffect(() => {
    caching();
    window.scrollTo(0, 0);
  }, [])

  let ecourslist = classes.map((item) => {
    let items = item.items.map((itembundle) => {
      return (
          <PricingItem>
            {/* <PricingTitle>{itembundle.description} : {itembundle.initialprice.toFixed(2)}$ - 35% = {itembundle.price.toFixed(2)}$</PricingTitle> */}
            <PricingTitle>{itembundle.description} : {itembundle.price.toFixed(2)}$</PricingTitle>
            <PricingButtom onClick={() => { notifyItemAdded(itembundle.id); props.addToCart(itembundle.id) }}>Ajouter au panier</PricingButtom>
          </PricingItem>

    )
    })
    return (
      <ECours>
        <Banner src={item.banner} />
        <EcoursText dangerouslySetInnerHTML={{ __html: item.full_description }}></EcoursText>
        <PricingWrapper>
        {items}
        </PricingWrapper>
      </ECours>
    )
  })

  return (
    <div className='site-wrapper'>
      <Nav />
      <NavLink />
      <ClassesWrapper>
        <ClassesTitle>Sur cette page, tu trouveras une multitude de e-cours avec des sujets variés afin que tu puisses sélectionner ceux qui te parle et qui t'aideront dans ton processus de développement personnel. Mes e-cours se basent sur mes connaissances dans le cadre mon BAC en Travail Social, de mon certificat avec des cours de psychologie, sur tous les documents, livres, audios et vidéos de professionnels que j'écoute depuis 4 ans et sur mon PROPRE développement personnel. Chaque cours est très complet et construis avec le plus de précisions et d'amour possible. À l'intérieur de ceux-ci, il y a beaucoup d'informations pour t'éduquer sur les différents sujets puisque c'est la base ! Comprendre la matière. Une fois qu'elle est comprise, c'est par le biais d'exercices que je t'aide à te transformer. Bien sûr, tu vas constater que je garde ma « couleur » à travers ta lecture. Autant que je peux être sérieuses, j'aime beaucoup l'humour (ou l'autodérision), qui est selon moi, le meilleur outil contre l'anxiété, la dépression, les pensées négatives, etc. Alors, autant que je te fournis de l'explication dans un texte plus sérieux, tu y trouveras aussi un langage plus « populaire » où je m'autorise à faire des blagues et utiliser quelques « lol » par-ci par-là. Ah oui et, je parle au « tu », étant donné qu'on se connaît bien lol. Je trouve que c'est plus personnel de m'adresser à toi ainsi ! <br /><br />Chaque cours comprend des exercices à faire et à prendre sérieusement pour atteindre tes objectifs. Certains cours ont aussi des audios ou même des vidéos. Tout le matériel parviendra par courriel.<br /><br /> Bonne transformation mon biscuit 🤍</ClassesTitle>
        <ECoursWrapper>
          {ecourslist}
        </ECoursWrapper>
      </ClassesWrapper>
      <Footer />
    </div>
  )


}

const mapStateToProps = (state) => {
  return {
    addedItems: state.shop.addedItems
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    addToCart: (id) => dispatch(addToCart(id)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Classes));