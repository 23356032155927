import React, { useEffect } from 'react'
import packageJson from '../../package.json'
import NavLink from '../components/NavLink'
import Footer from '../components/Footer';
import exercices from '../data/exercices'
import { ContentWrapper, ExercicesTitle, ExercicesWrapper, ImgExercice, ImgExerciceWrapper, ImgsWrapper, TextWrapper, VideosWrapper } from '../components/Exercices/ExercicesElements';
import Nav from '../components/Nav';

const Exercices = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let exos = exercices.map((item) => {
    return (
      <div className='iframe-container'>
        <iframe
          title={item.title}
          src={item.url}
          className="iframe"
          allowFullScreen
          scrolling="no"
          allow="encrypted-media;"
        ></iframe>
      </div>
    )
  })

  return (
    <div className='site-wrapper'>
      <Nav />
      <NavLink />
      <ExercicesWrapper>
        {/* <ExercicesTitle>Maecenas sed diam eget risus varius blandit sit amet non magna. Maecenas faucibus mollis interdum. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Maecenas faucibus mollis interdum.</ExercicesTitle> */}
        <TextWrapper>Je suis entraîneur privé depuis décembre 2020. Je suis surtout spécialisé en perte de poids / remise en forme. J'ai moi-même effectué une perte de poids en défaisant des croyances limitantes que j'avais. À l'époque, je ne savais pas comment perdre du poids et je pensais avoir une condition médicale particulière (ouin, j'étais dans le champ). Je ne savais juste pas comment m'y prendre à part manger de la salade. À travers une dizaine d'années j'ai perdu et repris du poids sans arrêt pour finalement comprendre comment le corps fonctionne, ET C'EST À CE MOMENT QUE J'AI EU LE CLIC, LA PASSION. Ayant moi-même vécu les hauts et les bas d'essayer de perdre du poids à travers les années, j'ai décidé d'aller chercher ma formation afin d'aider les gens qui ont dû mal à comprendre comment atteindre leurs objectifs physiques. J'ai réussi à passer d'une shape de « binge eating » à athlétique en 3 mois seulement, ce qui ne veut pas dire que ce sera ton cas. Néanmoins, j'ai une technique bien simple qui fonctionne.</TextWrapper>
        <ContentWrapper>
          <ImgsWrapper>
            <ImgExerciceWrapper>
              <ExercicesTitle>Transformation en 5 semaines</ExercicesTitle>
              <ImgExercice src='../assets/images/exercice1.jpg' />
            </ImgExerciceWrapper>
            <ImgExerciceWrapper>
              <ExercicesTitle>Transformation en 13 semaines</ExercicesTitle>
              <ImgExercice src='../assets/images/exercice2.jpg' />
            </ImgExerciceWrapper>
            <ImgExerciceWrapper>
              <ImgExercice src='../assets/images/exercice3.jpg' />
            </ImgExerciceWrapper>
            <ImgExerciceWrapper>
              <ImgExercice src='../assets/images/exercice4.jpg' />
            </ImgExerciceWrapper>
          </ImgsWrapper>
        <TextWrapper>Embarques-tu ?</TextWrapper>
          <VideosWrapper>
            {exos}
            {/* <VideoWrapper src='../assets/videos/abs.gif'></VideoWrapper> */}
          </VideosWrapper>
        </ContentWrapper>
      </ExercicesWrapper>
      <Footer />
    </div>
  )
}

export default Exercices