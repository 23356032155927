import styled from 'styled-components';
import { TbSquarePlus } from 'react-icons/tb'
import { TbSquareMinus } from 'react-icons/tb'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { NavLink as Link } from 'react-router-dom';

export const CartWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  min-width: 380px;
  top: 0;
  right: 0;
  opacity: ${({ isCartOpen }) => (isCartOpen ? '100%' : '0')};
  top: ${({isCartOpen}) => (isCartOpen ? '25px' : '-100%')};
  /* background-color: white; */
  transition: all 0.5s ease-in-out;
  /* border: solid 10px ${props => props.theme.colors.primary}; */
  border-radius: 0 0 0 50px;
  padding: ${props => props.theme.space.ms};
  padding-top: 100px;
  z-index: 4;
  background-image: linear-gradient(#e0b092, #b4c2cb);

  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 320px;
  }
`

export const CartItemWrapper = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  /* color: ${props => props.theme.colors.primary}; */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 5px;
  /* border-bottom: solid 1px ${props => props.theme.colors.primary} ;
  border-radius: 0 0 0 15px; */
`

export const CartInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  /* color: ${props => props.theme.colors.secondary}; */
  color: white;
`

export const CartTitle = styled.div`
  font-family: "Righteous", "sans-serif";
  /* font-family: ${props => props.theme.fonts.secondary}; */
  /* color: ${props => props.theme.colors.primary}; */
  color: white;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
  @media screen and (max-width: 1280px) {
    font-size: 1em;
  }
`

export const CartAdjustQty = styled.div`
  /* color: ${props => props.theme.colors.secondary}; */
  color: white;
  cursor: pointer;
  margin: 10px 5px;
  font-size: 13px;
`
export const RemoveIcon = styled(TbSquareMinus)`
font-size: 20px;
`

export const AddIcon = styled(TbSquarePlus)`
font-size: 20px;
`

export const CartPrice = styled.div`
  align-self: flex-start;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
  font-family: "Righteous", "sans-serif";
  margin-left: 15px;
  @media screen and (max-width: 1280px) {
    font-size: 1em;
  }
`

export const CartItemQty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 16px;
  font-family: serif;
`

export const CartType = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  /* color: ${props => props.theme.colors.secondary}; */
  color: white;
`

export const CartTotal = styled.div`
  align-self: flex-end;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.ms};
`

export const CartTotalWrapper = styled.div`
    font-family: "Righteous", "sans-serif";
  /* color: ${props => props.theme.colors.primary}; */
  color: white;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  border: solid 1px white;
  border-radius: 0 15px 0 15px;
  align-self: flex-start;
  margin: 10px 0;
`

export const CartTotalLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 10px;
`

export const CartTotalPrice = styled.div`
  align-self: flex-end;
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
`

export const CartPaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
`

export const CartGoPayment = styled(Link)`
  color: ${props => props.theme.colors.secondary};
  /* color: white; */
  min-width: 100px;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: ${props => props.theme.fontSizes.sm};
  border-radius: 20px;
  font-family: ${props => props.theme.fonts.serif};
  font-weight: bold;
  border: solid 1px white;
  background-color: white;
`

export const GoPayment = styled(FaLongArrowAltRight)`
  /* color: white; */
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSizes.md};
  vertical-align: middle;
`


export const ImgStripe = styled.img`
  width: 200px;
  height: auto;
  src: ${props => props.src};
`

export const CartPaymentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`
