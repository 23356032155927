import styled from 'styled-components';

export const MainLogo = styled.div`
  position: absolute;
  margin: ${props => props.theme.space.sm} ${props => props.theme.space.md};
  font-family: ${props => props.theme.fonts.cursive} ;
  font-size: ${props => props.theme.fontSizes.xl};
  color: ${props => props.theme.colors.primary};
  z-index: 5;
  width: 200px;
  height: 120px;
  background-image: url('/assets/icons/logo-rose.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media screen and (max-width: 1280px) {
    background-image: url('/assets/icons/logo-blanc-hor.png');
    position: relative;
    width: 250px;
    height: ${props => props.theme.space.ml};
    margin: 0 ${props => props.theme.space.sm};
    color: white; 
  }
  @media screen and (max-width: 500px) {
    width: 200px;
  }
`

export const SmallLogo = styled.div`
  background-image: url('/assets/icons/logo-blanc-hor.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: relative;
  width: 250px;
  height: ${props => props.theme.space.ml};
  margin: 0 ${props => props.theme.space.sm};
  color: white; 
  z-index: 5;
  @media screen and (max-width: 500px) {
    width: 200px;
  }
`


export const UpperRightBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ isFrontPage }) => (isFrontPage ? '40%' : '100%')};
  height: ${({ isFrontPage }) => (isFrontPage ? '60vh' : '75px')};
  background-color: ${props => props.theme.colors.primary};
  border-radius: 0 0 0 50px;
  z-index: -1;

  @media screen and (max-width: 1280px) {
    height: ${props => props.theme.space.ml};
    width: 100%;
    z-index: 4;
  }

  @media screen and (max-width: 550px) {
    border-radius: 0 0 0 30px;
  }
`