import React from 'react'
import { RdvTitle, RdvWrapper, PricingWrapper, PricingItem, PricingTitle, PricingButtom } from './RdvElements'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { addToCart } from '../../actions/shoppingActions';
import { toast } from 'react-toastify';
import rdvs from '../../data/rdv';

const Rdv = (props) => {
  const toastProps = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "toast-message"
  }
  const notifyItemAdded = (id) => {
    let existItem = props.addedItems.find(item => item.id === id)
    if (existItem) {
      toast('Article déjà au panier!', {
        ...toastProps
      })
    }
    toast('Item ajouté au panier!', {
      ...toastProps
    })
  }
  let rdvlist = rdvs[0].items.map((item) => {
    return (
      <PricingItem>
        <PricingTitle>Appel d'une durée de {item.duration} : {item.price}$</PricingTitle>
        <PricingButtom onClick={() => { notifyItemAdded(item.id); props.addToCart(item.id) }}>Ajouter au panier</PricingButtom>
      </PricingItem>
    )
  })

  return (
    <RdvWrapper>
      <RdvTitle>Réserve ta place pour une séance de développement personnel</RdvTitle>
      <PricingWrapper>
        {rdvlist}
      </PricingWrapper>
    </RdvWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    addedItems: state.shop.addedItems
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    addToCart: (id) => dispatch(addToCart(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Rdv));
