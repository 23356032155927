import React from 'react'
import { BannerWrapper, DoubleArrow, Product, ProductQuote, ProductsTitle, ProductsWrapper, ProductTitle } from './BannerProductsElements'

const BannerProducts = () => {
  return (
    <BannerWrapper>
      <ProductsTitle>Une combinaison parfaite pour arriver à ses buts</ProductsTitle>
      <ProductsWrapper>
        <Product to='/retreats'>
          <ProductQuote>Se ressourcer</ProductQuote>
          <ProductTitle>Retraites</ProductTitle>
        </Product>
        <DoubleArrow bg='/assets/icons/double-arrow-left.png'/>
        <Product to='/classes'>
          <ProductQuote>S'éduquer</ProductQuote>
          <ProductTitle>e-Cours</ProductTitle>
        </Product>
        <DoubleArrow bg='/assets/icons/double-arrow-right.png'/>
        <Product to='/exercices'>
          <ProductQuote>Se motiver</ProductQuote>
          <ProductTitle>Exercices</ProductTitle>
        </Product>
      </ProductsWrapper>
    </BannerWrapper>
  )
}

export default BannerProducts