import { ADD_TO_CART, REMOVE_FROM_CART, RESET_STATE, PAYMENT_SUCCESS, PAYMENT_PENDING, PAYMENT_ERROR, TOGGLE_CART, TOGGLE_MENU, TOGGLE_JOURNEY, CLOSE_ALL_DROPDOWN } from '../actions/actionTypes'
import classes from '../data/classes'
import retreats from '../data/retreats'
import rdvs from '../data/rdv'

const initState = {
  items: [ ...classes, ...retreats, ...rdvs ],
  addedItems: [],
  isCartOpen: false,
  isMenuOpen: false,
  total: 0,
  count: 0,
  pending: false,
  error: null,
  paymentPending: false,
  paymentSuccess: false,
  paymentError: false,
  customerEmail: null
}

const shoppingReducer = (state = initState, action) => {
  console.log(state)
  switch (action.type) {
    case ADD_TO_CART:
      let indexId = action.id.split("-")[0]
      let indexItem = state.items.find(item => item.id === indexId)
      let addedItem = indexItem.items.find(item => item.id === action.id)    
      let existItem = state.addedItems.find(item => item.id === action.id)
      if (!existItem) {
        return {
          ...state,
          addedItems: [...state.addedItems, addedItem],
          total: state.total + addedItem.price,
          count: state.count + 1
        }
      }
      return {
        ...state
      }
    case REMOVE_FROM_CART:
      let itemToRemove = state.addedItems.find(item => action.id === item.id)
      if (itemToRemove) {
        let newItems = state.addedItems.filter(item => action.id !== item.id)
        return {
          ...state,
          addedItems: newItems,
          total: state.total - itemToRemove.price,
          count: state.count - 1
        }
      }
      return {
        ...state
      }
    case TOGGLE_CART:
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
        isMenuOpen: false
      }
    case PAYMENT_PENDING:
      return {
        ...state,
        paymentPending: true
      }
    case PAYMENT_SUCCESS:
      return {
        ...state,
        paymentPending: false,
        paymentSuccess: true,
        customerEmail: action.email
      }
    case PAYMENT_ERROR:
      return {
        ...state,
        paymentPending: false,
        paymentError: action.error
      }
    case RESET_STATE:
      return {
        ...state,
        addedItems: [],
        total: 0,
        count: 0,
        pending: false,
        error: null,
        paymentSuccess: false,
        paymentPending: false,
        paymentError: false
      }
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen,
        isCartOpen: false
      }
    case TOGGLE_JOURNEY:
      return {
        ...state,
        isJourneyOpen: !state.isJourneyOpen,
        isCartOpen: false,
        isMenuOpen: false
      }
    case CLOSE_ALL_DROPDOWN:
      return {
        ...state,
        isCartOpen: false,
        isMenuOpen: false
      }
    default:
      return state
  }


}

export default shoppingReducer;