import styled from 'styled-components';

export const TestimonialsSectionWrapper = styled.div`
  min-height: 80vh;
  padding: 0 ${props => props.theme.space.md};
  position: relative;
  background-image: linear-gradient(#e0b092, rgb(192,204,211));
  padding-bottom: 15vh;
`
export const TestimonialsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: ${props => props.theme.space.md};
`

export const TestimonyWrapper = styled.div`
  align-self: ${({ align }) => (align)};
  width: 75%;
  font-family: ${props => props.theme.fonts.serif};
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Testimony = styled.div`
  font-size: ${props => props.theme.fontSizes.ms};
  text-align: center;
  font-weight: bold;
`

export const TestimonySub = styled.div`
  font-size: ${props => props.theme.fontSizes.xs};
  text-align: center;
  margin: 10px 0;
  font-style: italic;
`

