import styled from 'styled-components';

export const ArticleWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${props => props.theme.space.lg}; 
`

export const ArticleTitle = styled.div`
  text-align: center;
  padding: ${props => props.theme.space.sm} 0 ;
  margin: ${props => props.theme.space.sm} 0;
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSizes.lg};
  font-family: ${props => props.theme.fonts.cursive};
  position: relative;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35% 100%;
  @media screen and (max-width: 500px) {
    padding: ${props => props.theme.space.ms};
  }

`

export const ArticleParagraph = styled.div`
  position: relative;
  text-align: justify;
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.ms};
  margin: ${props => props.theme.space.sm}; 
  color: ${props => props.theme.colors.secondary};
  @media screen and (max-width: 500px) {
    margin: ${props => props.theme.space.ms};
  }
`

export const ArticleImg = styled.div` 
  position: relative;
  width: 30%;
  min-height: 250px;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  float: ${props => props.position};
  margin: 10px ${props => props.theme.space.md};
  border-radius: ${({position}) => (position === 'right' ? '0 0 50px 0;' : '50px 0 0 0')};
  @media screen and (max-width: 500px) {
    margin: 20px 10%;
    width: 80%;
  }
`