import styled from 'styled-components';

export const HomeContentWrapper = styled.div`
  padding-top: ${props => props.theme.space.lg};
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1280px) {
    margin-top: ${props => props.theme.space.md};
    padding-top: 0;
  }
`

export const HomeContentText = styled.div`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.ml};
  width: 30vw;
  min-width: 300px;
  margin: ${props => props.theme.space.sm};
  padding-left: ${props => props.theme.space.md};
  @media screen and (max-width: 1280px) {
    width: 100vw;
    text-align: center;
    margin: 0 ${props => props.theme.space.md};
  }

`

export const HomeContentSubLink = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.md};
  padding-top: ${props => props.theme.space.sm};
  float: right;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1280px) {
    float: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    font-size: ${props => props.theme.fontSizes.sm};
  }

`

export const SubLinkButton = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.sm};
  padding: ${props => props.theme.space.xs} 20px;
  cursor: pointer;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 50px;
  &:hover {
    background-color: ${props => props.theme.colors.primary};
  }
  @media screen and (max-width: 1450px) {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`

export const HomeContentImg = styled.div`
  width: 35vw;
  min-width: 300px;
  height: 40vh;
  margin-top: 1px;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 0 0 50px;
  cursor: pointer;
  @media screen and (max-width: 1280px) {
    width: 90vw;
  }
`

export const HeartArrow = styled.div`
  z-index: -1;
  float: right;
  width: 90px;
  height: 60px;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: -30px;
  margin-left: ${props => props.theme.space.xs};
  @media screen and (max-width: 1280px) {
    z-index: auto;
    float: none;
    transform: rotate(60deg);
    margin-top: 50px;
    width: 15vw;
  }
  @media screen and (max-width: 500px) {
    margin-top: 0 !important;
  }
`

export const LowerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  `

export const LowerLeftBg = styled.div`
  width: 40vw;
  height: 30vh;
  background-color: ${props => props.theme.colors.primary};
  opacity: 50%;
  border-radius: 0 50px 0 0;
  float: left;
  @media screen and (max-width: 1280px) {
    height: 50vh;
  }

`

export const SubContentWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.space.ms};
  @media screen and (max-width: 500px) {
    width: 100%;
    height: auto;
    float: right;
    text-align: justify;
    padding: ${props => props.theme.space.ms} 0 ${props => props.theme.space.sm} ${props => props.theme.space.sm} ;
    padding-right: 0;
  }
`

export const Spiral = styled.div`
  width: 150px;
  height: 150px;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: ${props => props.theme.space.ms};
  @media screen and (max-width: 600px) {
    display: none;
  }
`

export const SubContent = styled.div`
  color: ${props => props.theme.colors.primary};
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.sm};
  margin-right: ${props => props.theme.space.ms};
`

export const SubSubContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  `