import styled from 'styled-components';
import { AiOutlineShoppingCart } from 'react-icons/ai'

export const ClassesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10vh;
`

export const ClassesTitle = styled.div`
  width: 90%;
  margin-top: ${props => props.theme.space.md};
  text-align: center;
  font-family: ${props => props.theme.fonts.serif};
  /* font-size: ${props => props.theme.fontSizes.lg}; */
  font-size: ${props => props.theme.fontSizes.sm};
  color: ${props => props.theme.colors.secondary};
  text-align: justify;
  @media screen and (max-width: 750px) {
    width: 80%;
    font-size: ${props => props.theme.fontSizes.xs};
  }

`

export const SectionTitle = styled.div`
  align-self: flex-start;
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.ml};
  color: ${props => props.theme.colors.primary};
  margin: ${props => props.theme.fontSizes.sm};
`

export const BundleWrapper = styled.div`
  width: 60vw;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  background-image: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin: ${props => props.theme.space.md};
  padding: ${props => props.theme.space.md};
  padding-bottom: ${props => props.theme.space.lg};
  @media screen and (max-width: 1100px) {
    width: 90vw;
  }
`

export const Bundle = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  margin: ${props => props.theme.space.sm} 0;
  @media screen and (max-width: 750px) {
    width: 60%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }

`
export const BundleTitle = styled.div`
  font-family: ${props => props.theme.fonts.cursive};
  font-size: 2.2rem;
  color: ${props => props.theme.colors.primary};
  margin: ${props => props.theme.space.sm} 0;
`

export const BundleLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 5px 0;
`

export const BundleText = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.md};
  color: ${props => props.theme.colors.secondary};
  @media screen and (max-width: 750px) {
    font-size: ${props => props.theme.fontSizes.sm};
  }
`

export const BundleSelect = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.md};
  background-color: ${props => props.theme.colors.primary};
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: bold;
  padding-right: 5px;
  border-radius: 0 5px 0 0;
  cursor: pointer;

  &:active {
    background-color: ${props => props.theme.colors.secondary};
  }

  @media screen and (max-width: 750px) {
    font-size: ${props => props.theme.fontSizes.sm};
  }
`

export const CartIcon = styled(AiOutlineShoppingCart)`
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  margin: 5px;
  @media screen and (max-width: 750px) {
    font-size: ${props => props.theme.fontSizes.sm};
  }
`

export const ECoursWrapper = styled.div`
  margin: 50px;
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10vh;
  @media screen and (max-width: 750px) {
    width: 90%;
  }
`

export const ECours = styled.div`
  width: 100%;
  height: auto;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 50px;
  @media screen and (max-width: 750px) {
    border-radius: 30px;
  }
`

export const Banner = styled.img`
  width: 100%;
  height: 49vw;
  object-fit: cover;
  object-position: top;
  border-radius: 50px 50px 0px 0px;
  -moz-border-radius: 50px 50px 0px 0px;
  -webkit-border-radius: 50px 50px 0px 0px;
  @media screen and (max-width: 750px) {
    border-radius: 30px 30px 0px 0px;
  -moz-border-radius: 30px 30px 0px 0px;
  -webkit-border-radius: 30px 30px 0px 0px;
  height: 60vw;
  }
`

export const EcoursText = styled.div`
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.sm};
  color: white;
  text-align: justify;
  padding: 50px;
  @media screen and (max-width: 750px) {
    padding: 20px;
    font-size: ${props => props.theme.fontSizes.xs};
  }
`
export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const PricingItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: rgba(255,255,255,0.25);
  border-radius: 30px;
  min-height: 100px;
  padding: 30px;
  margin: 30px;
`

export const PricingTitle = styled.div`
  font-family: "Righteous";
  font-size: ${props => props.theme.fontSizes.md};
  color: white;
  text-align: center;
`

export const PricingButtom = styled.div`
  background-color: white;
  border-radius: 15px;
  color: ${props => props.theme.colors.primary};
  padding: 10px 20px;
  font-family: ${props => props.theme.fonts.serif};
  margin-top: 20px;
  cursor: pointer;
`
