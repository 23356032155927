import styled from 'styled-components';
import { BiUserCircle } from 'react-icons/bi'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { CgMenu } from 'react-icons/cg'

export const NavBarWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 75px;
  padding: 15px ${props => props.theme.space.sm};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  z-index: 5;
  /* background-color: ${props => props.theme.colors.primary}; */
`

export const UserIcon = styled(BiUserCircle)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`

export const CartIconWrapper = styled.div`
  display: ${({ isCheckout }) => (isCheckout ? 'none' : 'flex')};
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: ${props => props.theme.space.xs};
`

export const Badge = styled.p`
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding-top: 30px;
`

export const CartIcon = styled(AiOutlineShoppingCart)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  cursor: pointer;
`

export const MenuIcon = styled(CgMenu)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`