import styled from 'styled-components';

export const BannerWrapper = styled.div`
  height: 80vh;
  padding: ${props => props.theme.space.md};
  position: relative;
`

export const BannerTitle = styled.div`
  position: absolute;
  font-family: ${props => props.theme.fonts.cursive};
  color: white;
  font-size: ${props => props.theme.fontSizes.xl};
  top: 0;
  left: 0;
  margin: 50px;
  @media screen and (max-width: 500px) {
    font-size: ${props => props.theme.fontSizes.lg};
  }
`

export const BannerSubTitle = styled.div`
  position: absolute;
  font-family: ${props => props.theme.fonts.cursive};
  color: white;
  font-size: ${props => props.theme.fontSizes.xl};
  bottom: 0;
  right: 0;
  margin: 50px;
  @media screen and (max-width: 500px) {
    font-size: ${props => props.theme.fontSizes.lg};
  }
`

