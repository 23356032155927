import React from 'react'
import { PageWrapper } from '../Common/CommonElements'
import { Article, ArticleImg, ArticlePreview, ArticleTitle, ArticleWrapper, ReadMoreButton, UpperRightBg } from './ArticleSectionElements'
import articles from '../../data/articles'

const ArticleSection = () => {
  let articlegrid = articles.map((item) => {
    let link = "/article/" + item.id
    let readmore = item.readmore ? <ReadMoreButton>{item.readmore}</ReadMoreButton> : <></>;
    if (item.readmore === false) {
      return (
        <Article key={item.id} pointer={false}>
          <ArticleImg bg={item.cover} />
          <ArticleTitle>{item.title}</ArticleTitle>
          <ArticlePreview>{item.description}</ArticlePreview>
          {readmore}
        </Article>
      )
    } else {
      return (
        <Article key={item.id} to={link} pointer={true}>
          <ArticleImg bg={item.cover} />
          <ArticleTitle>{item.title}</ArticleTitle>
          <ArticlePreview>{item.description}</ArticlePreview>
          {readmore}
        </Article>
      )
    }
  })
  return (
    <PageWrapper>
      <UpperRightBg />
      <ArticleWrapper>
        {articlegrid}
      </ArticleWrapper>
    </PageWrapper>
  )
}

export default ArticleSection