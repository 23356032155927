import styled from 'styled-components';

export const RdvWrapper = styled.div`
  padding: ${props => props.theme.space.md};
  position: relative;
  background-color: ${props => props.theme.colors.secondary};
  min-height: 30vh;
  padding-bottom: 100px;
`

export const RdvTitle = styled.div`
 font-family: ${props => props.theme.fonts.cursive};
 font-size: 2.25em;
 color: white;
 text-align: center;
`

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const PricingItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 30px;
  min-height: 100px;
  padding: 30px;
  margin: 30px;
`

export const PricingTitle = styled.div`
  font-family: "Righteous";
  font-size: ${props => props.theme.fontSizes.md};
  color: white;
  text-align: center;
`

export const PricingButtom = styled.div`
  background-color: white;
  border-radius: 15px;
  color: ${props => props.theme.colors.primary};
  padding: 10px 20px;
  font-family: ${props => props.theme.fonts.serif};
  margin-top: 20px;
  cursor: pointer;
`

export const PricingOptions = styled.div`
  font-family: "Righteous";
  font-size: ${props => props.theme.fontSizes.md};
  color: ${props => props.theme.colors.secondary};
  opacity: 75%;
  text-align: center;
`