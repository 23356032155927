export const rdvs = [
  {
    id: 'rdv',
    items: [
      {
        id: 'rdv-30m,',
        price: 70,
        duration: "30 min",
        description: "Séance de développement personnel d'une durée de 30 minutes.",
        quantity: 1,
      },
      {
        id: 'rdv-1h',
        price: 100,
        duration: "1h",
        description: "Séance de développement personnel d'une durée de 1 heure.",
        quantity: 1,
      }
    ]
  }
]

export default rdvs;