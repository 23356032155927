import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { BsSpotify } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'

export const MenuWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 65px;
  right: 0;
  opacity: ${({ isMenuOpen }) => (isMenuOpen ? '100%' : '0')};
  right: ${({isMenuOpen}) => (isMenuOpen ? '0' : '-100%')};
  background-color: ${props => props.theme.colors.primary};
  transition: all 0.5s ease-in-out;
  /* border: solid 10px ${props => props.theme.colors.primary}; */
  border-radius: 0 0 0 50px;
  padding: ${props => props.theme.space.sm};
  z-index: 10;
  @media screen and (max-width: 1280px) {
    background-image: linear-gradient(#e0b092, #b4c2cb);
  }

`

export const MenuLink = styled(Link)`
  color: white;
  font-family: ${props => props.theme.fonts.cursive};
  font-size:1.75em;
  position: relative;
  text-decoration: none;
  margin: 5px;
  transition: all 0.25s ease-in-out;
  &:hover, &:active  {
    font-size:2em;
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
`

export const FbIcon = styled(FaFacebookF)`
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`

export const IgIcon = styled(BsInstagram)`
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`

export const SpotifyIcon = styled(BsSpotify)`
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`

export const TiktokIcon = styled(FaTiktok)`
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  margin: ${props => props.theme.space.xs};
  cursor: pointer;
`