import styled from 'styled-components';

export const ExercicesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 10vh;
  padding-top: 30px;
`

export const ExercicesTitle = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.space.md};
  text-align: center;
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.lg};
  color: ${props => props.theme.colors.primary};
  @media screen and (max-width: 750px) {
    font-size: ${props => props.theme.fontSizes.ml};
  }

`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: ${props => props.theme.space.md} ${props => props.theme.space.ms};
`

export const VideosWrapper = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
`

export const ImgsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  position: relative;
`
export const ImgExerciceWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  @media screen and (max-width: 1080px) {
    width: 90%;
  }
`

export const ImgExercice = styled.img`
  width: 100%;
  height: auto;
  src: ${props => props.src};
  margin-bottom: 50px;
`

export const TextWrapper = styled.div`
  width: 90%;
  min-width: 320px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: linear-gradient(#e0b092, #b4c2cb);
  font-family: ${props => props.theme.fonts.serif};
  border-radius: 50px 0 0 50px;
  color: white;
  padding: ${props => props.theme.space.ms};
  text-align: justify;
  line-height: 2rem;
  margin-top: 20px;

  @media screen and (max-width: 1080px) {
    width: 90%;
    border-radius: 0 50px 0 50px;
  }

`