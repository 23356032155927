import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const UpperRightBg = styled.div `
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 40vh;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 0 0 0 50px;
  z-index: -1;
`

export const ArticleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: ${props => props.theme.space.ml} ${props => props.theme.space.md} 0 ${props => props.theme.space.md};

`

export const Article = styled(Link)`
  width: 31vw;
  min-width: 320px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.serif};
  margin: 0 ${props => props.theme.space.sm};
  margin-bottom: ${props => props.theme.space.ml};
  text-decoration: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`

export const ArticleImg = styled.div`
  width: 100%;
  height: 40vh;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 50px 0 0;
`

export const ArticleTitle = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSizes.ml};
  padding: ${props => props.theme.space.sm} 0;
  align-self: flex-start;
`

export const ArticlePreview = styled.div`
  color: ${props => props.theme.colors.secondary};
  font-size: ${props => props.theme.fontSizes.sm};
  padding-bottom: ${props => props.theme.space.sm};
`

export const ReadMoreButton = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.secondary};
  color: white;
  font-size: ${props => props.theme.fontSizes.sm};
  padding: ${props => props.theme.space.xs};
  text-align: center;
`
