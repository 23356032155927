import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    // primary: "#e0b89f",
    // primary: "#e8b595",
    primary: "#e0b092",
    secondary: "#96aab6",
  },
  fonts: {
    // cursive: "'Lavishly Yours', cursive",
    cursive: "'Parisienne', cursive",
    // cursive: "'Tangerine', cursive",
    // cursive: "'Bad Script', cursive",
    serif: "'Baskervville', serif"
  },
  fontSizes: {
    xxs: "0.7em",
    xs: "0.85em",
    sm: "1em",
    md: "1.2em",
    ml: "1.6em",
    lg: "2em",
    xl: "2.5em",
    xxl: "4em",
  },
  space: {
    xs: "10px",
    sm: "20px",
    ms: "30px",
    md: "50px",
    ml: "75px",
    lg: "100px"
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;