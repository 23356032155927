import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { VscLoading } from 'react-icons/vsc'

export const LinkWrapper = styled(Link)``

export const Accent = styled.div`
  display: inline;
  color: ${props => props.theme.colors.secondary};
`

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
`

export const LoadingIcon = styled(VscLoading)`
  position: relative;
  width: 100%;
  line-height: 100%;
  margin: auto;
  font-size: 160px;
  color: white;
  animation: rotation 2s ease-in-out infinite;
  transition: all 2s ease-in-out;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`