import React, { Component } from 'react'
import { Navigate } from "react-router-dom";
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { CartItemWrapper, CartTitle, CartInfo, CartType, CartTotal, CartTotalWrapper, CartTotalLine, CartTotalPrice, CartPaymentWrapper, CartGoPayment, ImgStripe, CartPaymentButtonWrapper } from '../components/Cart/CartElements'
import { CheckoutBg, CheckoutConfirmation, CheckoutContentTitle, CheckoutContentWrapper, CheckoutItemPrice, CheckoutPage, CheckoutPendingWrapped } from '../components/Checkout/CheckoutElements';
import StripeCheckout from 'react-stripe-checkout'
import axios from "axios";
import { fetchPaymentError, fetchPaymentPending, fetchPaymentSuccess, resetState } from '../actions/shoppingActions';
import { LoadingIcon } from '../components/Common/CommonElements';
import { toast } from 'react-toastify';

class Checkout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    if (this.props.paymentSuccess || this.props.paymentError) {
      this.props.resetState();
    }
  }

  render() {
    const toastProps = {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "toast-message"
    }
    const toastPropsError = {
      position: "top-right",
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "toast-message"
    }
    const notifySuccess = () => {
      toast.success('Paiement bien reçu !', {
        ...toastProps
      })
    }
    const notifyError = (error) => {
      toast.error(error, {
        ...toastPropsError
      })
    }

    let addedItems = this.props.addedItems.length ?
      (
        this.props.addedItems.map((item) => {
          return (
            <CartItemWrapper>
              <CartInfo>
                <CartTitle>{item.quantity} x {item.description}</CartTitle>
                <CartType>{item.type}</CartType>
              </CartInfo>
              <CheckoutItemPrice>{(item.price * item.quantity).toFixed(2)}$</CheckoutItemPrice>
            </CartItemWrapper>
          )
        })) :
      (
        <Navigate to="/classes" replace={true} />
      );

    let subtotal = this.props.addedItems.length ?
      <CartPaymentWrapper>
        <CartTotalWrapper>
          <CartTotalLine>
            <CartTotal>Subtotal</CartTotal>
            <CartTotal>Taxes</CartTotal>
            <CartTotalPrice>Total</CartTotalPrice>
          </CartTotalLine>
          <CartTotalLine>
            <CartTotal>{this.props.total.toFixed(2)} $</CartTotal>
            <CartTotal>0.00 $</CartTotal>
            <CartTotalPrice>{this.props.total.toFixed(2)} $</CartTotalPrice>
          </CartTotalLine>
        </CartTotalWrapper>
        <CartPaymentButtonWrapper>
          <CartGoPayment>
            <StripeCheckout
              label="Payer maintenant"
              stripeKey="pk_live_51O76X4J8vL28Zsw2fOjYC2xXo9nn6XEa8twSuOvcTjcovnuUBRJqjLBroqwsuuKbfPcK2H6D3NPXqVFvn0IZNlUM00qHINSZgL"
              token={onToken(this.props.total, this.props.addedItems, this.props.fetchPaymentPending, this.props.fetchPaymentSuccess, this.props.fetchPaymentError, notifySuccess, notifyError)}
              billingAddress
              amount={this.props.total * 100}
              currency='CAD' />
          </CartGoPayment>
          <ImgStripe src='../assets/images/stripe.png' />
        </CartPaymentButtonWrapper>
      </CartPaymentWrapper>
      :
      <Navigate to="/classes" replace={true} />
      ;

    let paymentContent = this.props.paymentSuccess ? <CheckoutConfirmation>Merci ! Un courriel de confirmation te sera bientôt envoyé à l'adresse : {this.props.customerEmail}</CheckoutConfirmation> : this.props.paymentSuccess ? <CheckoutConfirmation>Merci ! Un courriel de confirmation te sera bientôt envoyé à l'adresse : {this.props.customerEmail}</CheckoutConfirmation> : subtotal;

    let content = this.props.paymentPending ?
      <CheckoutContentWrapper><LoadingIcon /></CheckoutContentWrapper>
      :
      <CheckoutContentWrapper>
        <CheckoutContentTitle>Sommaire de la commande</CheckoutContentTitle>
        {addedItems}
        {paymentContent}
      </CheckoutContentWrapper>

    return (
      <div className='site-wrapper'>
        <Nav isCheckout={true} />
        <CheckoutPage>
          <CheckoutBg src='/assets/icons/full-logo-blanc.png' />
          {content}
        </CheckoutPage>
        <Footer />
      </div>
    )
  }

}

const onToken = (amount, products, fetchPaymentPending, fetchPaymentSuccess, fetchPaymentError, notifySuccess, notifyError) => (token, args) => {
  fetchPaymentPending();
  const stripeAmount = amount * 100
  const route = process.env.REACT_APP_SRV_IP
  // axios.post("http://localhost:4242/checkout",
  axios.post(route,
    {
      token: token,
      price: stripeAmount,
      products: products
    })
    .then(json => { console.log(json); if (json.data.status == "success") { fetchPaymentSuccess(token.email); notifySuccess() } else { fetchPaymentError("error"); notifyError(json.data.status) } })
    .catch(err => { notifyError("Un problème externe est survenu. Veuillez essayer de nouveau un peu plus tard!"); console.log(err); fetchPaymentError(err); });
}

const mapStateToProps = (state) => {
  return {
    paymentPending: state.shop.paymentPending,
    paymentSuccess: state.shop.paymentSuccess,
    paymentError: state.shop.paymentError,
    addedItems: state.shop.addedItems,
    total: state.shop.total,
    customerEmail: state.shop.customerEmail
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    fetchPaymentPending: () => { dispatch(fetchPaymentPending()) },
    fetchPaymentSuccess: (email) => { dispatch(fetchPaymentSuccess(email)) },
    fetchPaymentError: (error) => { dispatch(fetchPaymentError(error)) },
    resetState: () => { dispatch(resetState()) }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);