import styled from 'styled-components';
import { BsMailbox } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram } from 'react-icons/bs'
import { BsSpotify } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'

export const FooterWrapper = styled.div`
  height: 10vh;
  width: 100%;
  padding: ${props => props.theme.space.xs};
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 50px 50px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
`

export const PoweredByWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

export const PoweredByHead = styled.div`
  font-family: 'Raleway', sans-serif;
  color: white;
  font-size: ${props => props.theme.fontSizes.ms};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.xxs};
  }
`

export const PoweredBySub = styled.div`
  font-family: 'Raleway', sans-serif;
  color: white;
  font-size: ${props => props.theme.fontSizes.md};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.xs};
    text-align: center;
  }
`

export const SendEmailIcon = styled(BsMailbox)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin: 0 ${props => props.theme.space.md};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.md};
    margin: 0 ${props => props.theme.space.ms};
  }
`

export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
`

export const FbIcon = styled(FaFacebookF)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin-left: ${props => props.theme.space.ms};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.md};
    margin-left: ${props => props.theme.space.sm};
  }
`

export const IgIcon = styled(BsInstagram)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin: 0 ${props => props.theme.space.ms};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.md};
    margin: 0 ${props => props.theme.space.sm};
  }
`

export const SpotifyIcon = styled(BsSpotify)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin-right: ${props => props.theme.space.ms};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.md};
    margin-right: ${props => props.theme.space.sm};
  }
`

export const TiktokIcon = styled(FaTiktok)`
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin-right: ${props => props.theme.space.ms};
  @media screen and (max-width: 700px) {
    font-size: ${props => props.theme.fontSizes.md};
    margin-right: ${props => props.theme.space.ms};
  }
`