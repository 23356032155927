import React, {useRef,useEffect} from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import Cart from '../Cart';
import { MainLogo, SmallLogo, UpperRightBg } from './NavElements'
import Menu from '../Menu';
import NavBar from '../NavBar'
import { BackArrow } from '../Checkout/CheckoutElements';
import { LinkWrapper } from '../Common/CommonElements';
import { closeDropdowns } from '../../actions/shoppingActions';

const Nav = (props) => {
  function useComponentVisible() {
    const ref = useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            props.closeDropdowns();
        } 
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, []);

    return { ref };
}


  const { ref } = useComponentVisible();

  let logo = props.isFrontPage ? <LinkWrapper to='/'><MainLogo/></LinkWrapper> : props.isCheckout ? <LinkWrapper to='/'><BackArrow /></LinkWrapper>:  <LinkWrapper to='/'><SmallLogo /></LinkWrapper>;
  return ( <div ref={ref}>
    {logo}
    {/* <MainLogo bg='/assets/icons/logo-short-rose.png'></MainLogo> */}
    <UpperRightBg isFrontPage={props.isFrontPage} />
    <NavBar isCheckout={props.isCheckout} />
    <Menu />
    <Cart />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    closeDropdowns: () => dispatch(closeDropdowns())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Nav));