export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const TOGGLE_CART = 'TOGGLE_CART';

export const PAYMENT_PENDING = 'PAYMENT_PENDING';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';

export const FETCH_ARTICLES_PENDING = 'FETCH_ARTICLES_PENDING';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_ERROR = 'FETCH_ARTICLES_ERROR';

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_JOURNEY = 'TOGGLE_JOURNEY';
export const CLOSE_ALL_DROPDOWN = 'CLOSE_ALL_DROPDOWN'

export const RESET_STATE = 'RESET_STATE';