import styled from 'styled-components';
import { CgClose } from 'react-icons/cg'

export const JourneyWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: ${({isJourneyOpen}) => (isJourneyOpen ? 'flex' : 'none')};
  background-color: none;
  color: white;
  z-index: 10;
  padding: ${props => props.theme.space.ms};
  padding-top: 75px;
  height: 100vh;
  width: 100vw;
`

export const CloseJourney = styled(CgClose)`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-size: ${props => props.theme.fontSizes.ml};
  margin: ${props => props.theme.space.sm};
  cursor: pointer;
`

export const JourneyContentWrapper = styled.div`
  position: relative;
  box-shadow: 0px 0px 15px 5px rgba(256,256,256,0.5);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  top: 0;
  white-space: pre-line;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 50px 0 0 0;
`

export const JourneyText = styled.div`
  position: relative;
  text-align: justify;
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.ms};
  margin: ${props => props.theme.space.md} ${props => props.theme.space.ms};
  margin-bottom: 0;
`

export const JourneyTitle = styled.div`
  position: relative;
  text-align: justify;
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.ml};
  margin: ${props => props.theme.space.sm};
  margin-bottom: -30px;
`

export const JourneyImg = styled.div` 
  width: ${({vertical}) => (vertical ? '35%' : '55%')};
  min-height: ${({vertical}) => (vertical ? '60vh' : '70%')};
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0 0 0 50px;
  float: ${props => props.position};
  margin: 5px ${props => props.theme.space.ms};
  margin-left: ${({position}) => (position === 'left' ? '50px' : '30px')};
  margin-right: ${({position}) => (position === 'right' ? '50px' : '30px')};
  border-radius: ${({position}) => (position === 'right' ? '0 0 50px 0;' : '50px 0 0 0')};
  @media screen and (max-width: 700px) {
    width: 100%;
    margin: 50px ${props => props.theme.space.sm} !important;
    border-radius: ${({position}) => (position === 'right' ? '0 50px 50px 0;' : '50px 0 0 50px')};
  }
`
