import React, { useEffect } from 'react'
import packageJson from '../../package.json'
import NavLink from '../components/NavLink'
import { ButtonWrapper, PricingButtom, PricingItem, PricingOptions, PricingTitle, PricingWrapper, Retreat, RetreatButton, RetreatDesc, RetreatImage, RetreatPlace, RetreatsWrapper, RetreatTitle, TestimonialLink, TitleWrapper } from '../components/Retreats/RetreatsElements'
import Footer from '../components/Footer';
import retreats from '../data/retreats'
import Nav from '../components/Nav';
import Gallery from 'react-photo-gallery';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { addToCart } from '../actions/shoppingActions';
import { toast } from 'react-toastify';


const Retreats = (props) => {
  const caching = () => {
    let version = localStorage.getItem('version');
    console.log(version)
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }

  const toastProps = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: "toast-message"
  }
  const notifyItemAdded = (id) => {
    let existItem = props.addedItems.find(item => item.id === id)
    if (existItem) {
      toast('Article déjà au panier!', {
        ...toastProps
      })
    }
    toast('Item ajouté au panier!', {
      ...toastProps
    })
  }
  useEffect(() => {
    caching();
    window.scrollTo(0, 0);
  }, [])

  let retreatlist = retreats.map((item) => {
    let full = item.place ? false : true;
    let remaining = full ? 'COMPLET' : item.place + " places restantes"
    if (item.type === "retreat") {
      if (item.finished) { /////// PASSED RETREATS
        return (
          <Retreat key={item.id} id={item.id} full={full}>
            {/* <RetreatImage bg={item.image}> </RetreatImage> */}
            <TitleWrapper>
              <RetreatTitle>{item.location}</RetreatTitle>
              <RetreatPlace full={false}>{item.date}</RetreatPlace>
            </TitleWrapper>
            <Gallery photos={item.photos} />
            {/* <RetreatDesc>{item.description}</RetreatDesc> */}
            {/* <RetreatButton full={full} onClick={() => window.location = 'mailto:info@avecesmeralda.ca'}>Réserve ta place maintenant!</RetreatButton> */}
          </Retreat>
        )
      } else { /////// CURRENT RETREATS
        let items = item.items.map((itembundle) => {
          return (
            <PricingItem>
              <PricingTitle>{itembundle.short} : {itembundle.price.toFixed(2)}$</PricingTitle>
              <PricingButtom onClick={() => { notifyItemAdded(itembundle.id); props.addToCart(itembundle.id) }}>Ajouter au panier</PricingButtom>
              {/* <PricingButtom >Ajouter au panier</PricingButtom> */}
            </PricingItem>

          )
        })
        return (
          <Retreat key={item.id} id={item.id} full={full} border={true}>
            {/* <RetreatImage bg={item.image}> </RetreatImage> */}
            <TitleWrapper>
              <RetreatTitle>{item.location}</RetreatTitle>
              <RetreatTitle>{item.date}</RetreatTitle>
              <RetreatPlace full={false}>Complet</RetreatPlace>
            </TitleWrapper>
            <Gallery photos={item.photos} />
            <RetreatDesc dangerouslySetInnerHTML={{ __html: item.description }}></RetreatDesc>
            {/* <PricingWrapper>
              <a href="mailto:audreyboyte@hotmail.fr">
                <PricingItem>
                  <PricingTitle >Contactez-moi directement pour discuter de l'inscription et des arrangements de paiement.</PricingTitle>
                </PricingItem></a>
            </PricingWrapper>
            <PricingOptions> - OU - </PricingOptions> */}
            <PricingOptions>Paiement disponible seulement pour les places déjà réservées ⬇️</PricingOptions>
            <PricingWrapper>
              {items}
            </PricingWrapper>
          </Retreat>
        )
      }
    } else { /////// TEXT INTRO
      return (
        <Retreat key={item.id} id={item.id} full={false}>
          <TitleWrapper>
            <RetreatTitle>{item.location}</RetreatTitle>
          </TitleWrapper>
          <RetreatDesc dangerouslySetInnerHTML={{ __html: item.description }}></RetreatDesc>
          <TitleWrapper>
            <RetreatTitle>{item.title2}</RetreatTitle>
          </TitleWrapper>
          <RetreatDesc dangerouslySetInnerHTML={{ __html: item.description2 }}></RetreatDesc>
          <TestimonialLink to='/article/retraite'>Consultes les témoignages de femmes extraordinaires</TestimonialLink>
          <RetreatDesc dangerouslySetInnerHTML={{ __html: item.description3 }}></RetreatDesc>
        </Retreat>
      )
    }

  })

  return (
    <div className='site-wrapper'>
      <Nav />
      <NavLink />
      <RetreatsWrapper>
        {retreatlist}
      </RetreatsWrapper>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    addedItems: state.shop.addedItems
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    addToCart: (id) => dispatch(addToCart(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Retreats));