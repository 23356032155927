import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const Links = styled(Link)`
  border: solid 1px ${props => props.theme.colors.primary};
  border-radius: 0 0 25px 25px;
  color: ${props => props.theme.colors.primary};
  background-color: white;
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.ml};
  padding: 5px 3vw;
  margin: 0 2vw;
  top: -2px;
  position: relative;
  text-decoration: none;

  &.active {
    color: white;
    background-color: #e0b092;
  }

  &:hover {
    color: white;
    background-color: #e0b092;
    opacity: 75%;
  }

  @media screen and (max-width: 550px) {
    font-size: 20px;
    border-radius: 0 0 20px 20px;
  }

`

