export const classes = [
  {
    id: 'rm',
    category: 'relations',
    kind: 'e-cours',
    banner: '/assets/images/banner.jpeg',
    name: 'Bye bye relations malsaines!',
    small_description: 'Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Curabitur blandit tempus porttitor.',
    full_description: "Es-tu dépendant affectif sans le savoir ? Es-tu tanné d'entretenir des relations toxiques ? <br><br>À travers ce cours, je t'offre une approche complète pour guérir ta dépendance affective et enfin t'amener à vivre des relations saines et épanouissantes. <br><br>Que tu as travaillé avec moi dans le passé ou que tu sois ici pour la première fois, ce cours sera ton remontant et ton coup de pouce pour te remettre sur la bonne voie. Je t'amène à te libérer de tes schémas toxiques et te diriger vers des personnes qui seront nourrissantes pour ton bien-être. <br><br>Pour se faire, voici ce que nous aborderons : <br><br><b>Semaine 1 - Comprendre la dépendance affective</b> <br><br><ul><li> Analyse de tes relations</li><li> Les signes et symptômes courants de la dépendance affective</li><li> Les causes sous-jacentes, y compris les expériences passées et les schémas de comportement.</li><li> Identifier tes besoins</li><li> Pratique de la pleine conscience</li><li> Blessure du rejet et d'abandon</li></ul><br><br><b>Semaine 2 - Renforcer ton estime de toi</b><br><br><ul><li>Création d’affirmations positives</li><li>Acceptation de soi</li><li>Gratitude</li><li>Réalisations & qualités</li><li>Gestion des émotions</li></ul><br><br><b>Semaine 3 - Indépendance émotionnelle</b><br><br><ul><li>Reconnaître ses propres limites</li><li>Apprendre à communiquer ses limites</li><li>Apprivoiser la solitude</li><li>Apprendre à dire non</li><li>Conclusion</li></ul><br><br>Lors du paiement, veuillez entrer l'adresse e-mail à laquelle vous souhaitez recevoir le matériel.<br><br><i>Il n'y aura aucun remboursement une fois que vous aurez commencé le cours. </i><br><br> Si vous avez des questions, veuillez nous envoyer un e-mail à <b><a href='mailto:audreyboyte@hotmail.fr'>audreyboyte@hotmail.fr</a></b>.",
    items: [
      {
        id: 'rm-ecours',
        initialprice: 77.77,
        price: 22.22,
        discount: '35%',
        description: "e-Cours sur les relations malsaines",
        quantity: 1,
        productfile: "/products/rm-ecours.pdf",
      },
      // {
      //   id: 'rm-ecours-2seances30m',
      //   initialprice: 188.88,
      //   price: 188.88,
      //   discount: '20%',
      //   description: "e-Cours + 2 séances de développement personnel de 30 minutes",
      //   quantity: 1,
      //   productfile: "/products/rm-ecours.pdf",
      // },
    ]
  },
]

export default classes;