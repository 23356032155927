import { TOGGLE_MENU, TOGGLE_JOURNEY } from '../actions/actionTypes'

const initState = {
  isMenuOpen: false,
  isJourneyOpen: false
}

const miscReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isMenuOpen: !state.isMenuOpen
      }
    case TOGGLE_JOURNEY:
      return {
        ...state,
        isJourneyOpen: !state.isJourneyOpen
      }
    default:
      return state
  }
}

export default miscReducer;