import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { FbIcon, IgIcon, MenuLink, MenuWrapper, SocialWrapper, SpotifyIcon, TiktokIcon } from './MenuElements'
import {isMobile} from 'react-device-detect';
import { toggleJourney } from '../../actions/shoppingActions';

const Menu = (props) => {
  let fblink = isMobile ? "fb://page/audrey.boyte.3" : "https://www.facebook.com/audrey.boyte.3"
  let instalink = isMobile ? "instagram://user?username=esmeraldaa____" : "https://www.instagram.com/esmeraldaa____"
  let spotifylink = isMobile ? "spotify:track:5OvEVshnMGR73KITaLD84Q" : "https://open.spotify.com/show/5OvEVshnMGR73KITaLD84Q"
  let tiktoklink = isMobile ? "https://www.tiktok.com/@esmeraldaa____" : "https://www.tiktok.com/@esmeraldaa____"
  return (
    <MenuWrapper isMenuOpen={props.isMenuOpen}>
      <MenuLink to='/' onClick={() => { props.toggleJourney() }}>À propos de moi</MenuLink>
      <MenuLink to='/retreats'>Retraites</MenuLink>
      <MenuLink to='/classes'>e-Cours</MenuLink>
      <MenuLink to='/exercices'>Exercices</MenuLink>
      <SocialWrapper>
        <a href={fblink} target="_blank" rel="noopener noreferrer"><FbIcon /></a>
        <a href={instalink} target="_blank" rel="noopener noreferrer"><IgIcon /></a>
        <a href={spotifylink} target="_blank" rel="noopener noreferrer"><SpotifyIcon /></a>
        <a href={tiktoklink} target="_blank" rel="noopener noreferrer"><TiktokIcon /></a>
      </SocialWrapper>
    </MenuWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isMenuOpen: state.shop.isMenuOpen
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    toggleJourney: (id) => dispatch(toggleJourney()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Menu));