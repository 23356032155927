import { ADD_TO_CART, REMOVE_FROM_CART, TOGGLE_CART, PAYMENT_ERROR, PAYMENT_PENDING, PAYMENT_SUCCESS, RESET_STATE, TOGGLE_MENU, TOGGLE_JOURNEY, CLOSE_ALL_DROPDOWN } from '../actions/actionTypes'

export const addToCart = (id) => {
  return {
    type: ADD_TO_CART,
    id
  }
}

export const removeFromCart = (id) => {
  return {
    type: REMOVE_FROM_CART,
    id
  }
}

export const toggleCart = () => {
  return {
    type: TOGGLE_CART
  }
}

export const fetchPaymentPending = () => {
  return {
    type: PAYMENT_PENDING,
  }
}

export const fetchPaymentSuccess = (email) => {
  return {
    type: PAYMENT_SUCCESS,
    email
  }
}

export const fetchPaymentError = (error) => {
  return {
    type: PAYMENT_ERROR,
    error
  }
}

export const resetState = () => {
  return {
    type: RESET_STATE,
  }
}

export const toggleMenu = () => {
  return {
    type: TOGGLE_MENU
  }
}

export const toggleJourney = () => {
  return {
    type: TOGGLE_JOURNEY
  }
}

export const closeDropdowns = () => {
  return {
    type: CLOSE_ALL_DROPDOWN
  }
}