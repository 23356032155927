import { FETCH_ARTICLES_PENDING, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_ERROR } from '../actions/actionTypes'

const initState = {
  articles: [],
  articlesPending: false,
  articlesError: false,
}

const contentReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ARTICLES_PENDING:
      return {
        ...state,
        articlesPending: true
      }
    case FETCH_ARTICLES_SUCCESS:
      return {
        ...state,
        articlesPending: false,
        articlesError: false,
      }
    case FETCH_ARTICLES_ERROR:
      return {
        ...state,
        articlesPending: false,
        articlesError: true
      }
    default:
      return state
  }
}

export default contentReducer;