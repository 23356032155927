import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { HeartArrow, HomeContentImg, HomeContentSubLink, HomeContentText, HomeContentWrapper, LowerLeftBg, LowerWrapper, Spiral, SubContent, SubContentWrapper, SubLinkButton, SubSubContentWrapper } from './FrontPageElements'
import { Accent, PageWrapper } from '../Common/CommonElements'
import { toggleJourney } from '../../actions/shoppingActions'

const FrontPage = (props) => {
  return (
    <PageWrapper>
      <HomeContentWrapper>
        <HomeContentText>
          Deviens ce que tu veux <Accent>attirer</Accent>.
          <HomeContentSubLink onClick={props.toggleJourney}>
            <SubLinkButton>Qui est cette femme ?</SubLinkButton><HeartArrow bg="/assets/icons/heart-arrow.png" /></HomeContentSubLink>
        </HomeContentText>
        <HomeContentImg bg="/assets/images/1.jpeg" onClick={props.toggleJourney} />
      </HomeContentWrapper>

      <LowerWrapper>
        <LowerLeftBg />
        <SubContentWrapper>
          <Spiral bg="/assets/icons/spiral.png" />
          <SubSubContentWrapper>
            <SubContent style={{ fontWeight: 'bold'}}>MA MISSION :</SubContent>
            <SubContent>Aider le plus de personnes possible à <Accent>croire en eux</Accent>, à voir la vie le verre à moitié plein, à réaliser leurs <Accent>rêves</Accent>, à apprécier la personne qu'ils sont, même avec les imperfections, à <Accent>SE</Accent> respecter et <Accent>SE FAIRE</Accent> respecter, à ne plus avoir de comportements toxiques pour eux ou pour les autres. Amener les gens à faire ce que <Accent>J'AI</Accent> fait pour moi-même. Passer d'une vie ordinaire avec un mental de victime à une vie <Accent>épanouissante</Accent>, riche en expérience et beaucoup plus positive.</SubContent>
          </SubSubContentWrapper>
        </SubContentWrapper>
      </LowerWrapper>
    </PageWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isJourneyOpen: state.shop.isJourneyOpen
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    toggleJourney: () => dispatch(toggleJourney())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FrontPage));