import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const BannerWrapper = styled.div`
  padding: ${props => props.theme.space.md};
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 0 50px 0 0;
`

export const ProductsTitle = styled.div`
 font-family: ${props => props.theme.fonts.cursive};
 font-size: 2.25em;
 color: white;
 text-align: center;
`

export const ProductsWrapper = styled.div`
  width: 100%;
  padding: 0 ${props => props.theme.space.md};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`

export const Product = styled(Link)`
  width: 300px;
  height: 200px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 30px;
  border: solid 2px white;
  text-align: center;
  margin: ${props => props.theme.space.md} 0;
  text-decoration: none;
`

export const ProductQuote = styled.div`
  width: 300px;
  height: 50px;
  line-height: 50px;
  color: white;
  border-radius: 30px 30px 0 0;
  font-family: ${props => props.theme.fonts.serif};
  font-size: ${props => props.theme.fontSizes.md};
`

export const ProductTitle = styled.div`
  width: 300px;
  height: 146px;
  line-height: 146px;
  color: ${props => props.theme.colors.primary};
  background-color: white;
  font-family: ${props => props.theme.fonts.cursive};
  font-size: ${props => props.theme.fontSizes.xl};
  border-radius: 0 0 30px 30px;
  border: solid 2px white;
  margin-left: -4px;
  align-self: center;
`

export const DoubleArrow = styled.div`
  width: 70px;
  height: 50px;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (max-width: 1280px) and (min-width: 551px) {
    display: none;
  }
  @media screen and (max-width: 550px) {
    transform: rotate(90deg);
  }
`

