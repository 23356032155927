export const retreats = [
  {
    id: 'exo1',
    url: "https://www.instagram.com/p/CSg-7nDLBOy/embed",
  },
  {
    id: 'exo2',
    url: "https://www.instagram.com/p/CSNKKiWp4-6/embed",
  },
  {
    id: 'exo3',
    url: "https://www.instagram.com/p/CSHiauJLlhk/embed",
  },
  {
    id: 'exo4',
    url: "https://www.instagram.com/p/CRjMfb5BWy_/embed",
  },
  {
    id: 'exo5',
    url: "https://www.instagram.com/p/CPLzngaB5To/embed",
  },
  {
    id: 'exo6',
    url: "https://www.instagram.com/p/CNmA-8nhjHh/embed",
  },
  {
    id: 'exo7',
    url: "https://www.instagram.com/p/CMz05ZqhwAp/embed",
  },
]

export default retreats;