import React, { useEffect } from 'react'
import packageJson from '../../package.json'
import ArticleSection from '../components/Article';
import Banner from '../components/Banner';
import BannerProducts from '../components/BannerProducts';
import Footer from '../components/Footer';
import FrontPage from '../components/FrontPage';
import Journey from '../components/Journey';
import Nav from '../components/Nav';
import Testimonials from '../components/Testimonials';
import Rdv from '../components/Rdv';

const Home = () => {
  const caching = () => {
    let version = localStorage.getItem('version');
    console.log(version)
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }

  useEffect(() => {
    caching();
  }, []);

  return (
    <div className='site-wrapper'>
      <Nav isFrontPage={true}/>
      <FrontPage />
      <Journey />
      <Banner />
      <ArticleSection />
      <BannerProducts />
      <Testimonials />
      <Rdv />
      <Footer />
    </div>
  )
}

export default Home