import React from 'react'
import { FbIcon, FooterWrapper, IgIcon, PoweredByHead, PoweredBySub, PoweredByWrapper, SendEmailIcon, SocialWrapper, SpotifyIcon, TiktokIcon } from './FooterElements'
import {isMobile} from 'react-device-detect';

const Footer = () => {
  let fblink = isMobile ? "fb://page/audrey.boyte.3" : "https://www.facebook.com/audrey.boyte.3"
  let instalink = isMobile ? "instagram://user?username=esmeraldaa____" : "https://www.instagram.com/esmeraldaa____"
  let spotifylink = isMobile ? "spotify:track:5OvEVshnMGR73KITaLD84Q" : "https://open.spotify.com/show/5OvEVshnMGR73KITaLD84Q"
  let tiktoklink = isMobile ? "https://www.tiktok.com/@esmeraldaa____" : "https://www.tiktok.com/@esmeraldaa____"

  return (
    <FooterWrapper>
      <a href="mailto:info@avecesmeralda.ca"><SendEmailIcon /></a>
      <a href="mailto:octa.web.solutions@gmail.com"><PoweredByWrapper>
        <PoweredByHead>Propulsé par:</PoweredByHead>
        <PoweredBySub>Octaweb Solutions</PoweredBySub>
      </PoweredByWrapper></a>
      <SocialWrapper>
        <a href={fblink} target="_blank" rel="noopener noreferrer"><FbIcon /></a>
        <a href={instalink} target="_blank" rel="noopener noreferrer"><IgIcon /></a>
        <a href={spotifylink} target="_blank" rel="noopener noreferrer"><SpotifyIcon /></a>
        <a href={tiktoklink} target="_blank" rel="noopener noreferrer"><TiktokIcon /></a>
      </SocialWrapper>
    </FooterWrapper>
  )
}

export default Footer