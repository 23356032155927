import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { removeFromCart, addToCart, toggleCart, closeDropdowns } from '../../actions/shoppingActions';
import { CartWrapper, CartItemWrapper, CartTitle, CartPrice, CartInfo, RemoveIcon, CartAdjustQty, CartItemQty, AddIcon, CartType, CartTotal, CartTotalWrapper, CartTotalLine, CartTotalPrice, CartPaymentWrapper, CartGoPayment, GoPayment } from './CartElements'

const Cart = (props) => {

  let addedItems = props.addedItems.length ?
    (
      props.addedItems.map((item) => {
        return (
          <CartItemWrapper>
            <CartInfo>
              <CartTitle>{item.description}</CartTitle>
              <CartType>{item.type}</CartType>
              <CartItemQty>

                {/* <CartAdjustQty>{item.quantity}</CartAdjustQty> */}
                <CartAdjustQty onClick={() => { props.removeFromCart(item.id) }}>Retirer</CartAdjustQty>
                {/* <CartAdjustQty onClick={() => { props.addToCart(item.id) }}><AddIcon /></CartAdjustQty> */}
              </CartItemQty>
            </CartInfo>
            <CartPrice>{(item.price * item.quantity).toFixed(2)}$</CartPrice>
          </CartItemWrapper>
        )
      })) :
    (
      <CartItemWrapper>Le panier est vide !</CartItemWrapper>
    );

  let subtotal = props.addedItems.length ?
  <CartPaymentWrapper>
    <CartTotalWrapper>
      <CartTotalLine>
        <CartTotal>Sous-total</CartTotal>
        <CartTotal>Taxes</CartTotal>
        <CartTotalPrice>Total</CartTotalPrice>
      </CartTotalLine>
      <CartTotalLine>
        <CartTotal>{props.total.toFixed(2)} $</CartTotal>
        <CartTotal>0.00 $</CartTotal>
        <CartTotalPrice>{props.total.toFixed(2)} $</CartTotalPrice>
      </CartTotalLine>
    </CartTotalWrapper>
    <CartGoPayment to='/checkout' onClick={() => { props.closeDropdowns() }}>Procéder au paiement <GoPayment /></CartGoPayment>
    </CartPaymentWrapper>
    :
    <></>
    ;

  return (
    <CartWrapper isCartOpen={props.isCartOpen} className="cart-wrapper">
      {addedItems}
      {subtotal}
    </CartWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    isCartOpen: state.shop.isCartOpen,
    addedItems: state.shop.addedItems,
    total: state.shop.total
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    addToCart: (id) => dispatch(addToCart(id)),
    toggleCart: () => dispatch(toggleCart()),
    closeDropdowns: () => dispatch(closeDropdowns())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cart));