import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { CartIcon, CartIconWrapper, MenuIcon, NavBarWrapper, Badge } from './NavBarElements'
import { toggleCart } from '../../actions/shoppingActions';
import { toggleMenu } from '../../actions/shoppingActions';

const NavBar = (props) => {

  let bagde = props.count ? (<Badge>{props.count}</Badge>) : (<></>);
  return (
    <NavBarWrapper>
      {/* <UserIcon /> */}
      <CartIconWrapper isCheckout={props.isCheckout}>
        <CartIcon onClick={props.toggleCart}/>
        {bagde}
      </CartIconWrapper>
      <MenuIcon onClick={props.toggleMenu}/>
    </NavBarWrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    count: state.shop.count,
    isCartOpen: state.shop.isCartOpen,
    isMenuOpen: state.shop.isMenuOpen
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    toggleCart: () => dispatch(toggleCart()),
    toggleMenu: () => dispatch(toggleMenu()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NavBar));