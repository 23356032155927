import React from 'react'
import { Parallax } from 'react-parallax';
import { BannerSubTitle, BannerTitle, BannerWrapper } from './BannerElements';

const Banner = () => {
  return (
    <Parallax
      bgImage="/assets/images/2.jpeg"
      bgImageAlt="Meditation at the beach"
      bgClassName='object-bottom'
      strength={200}>
      <BannerWrapper>
        <BannerTitle>Si tu veux quelque chose que tu n'as jamais eu...</BannerTitle>
        <BannerSubTitle>tu dois faire quelque chose que tu n'as jamais fait</BannerSubTitle>
      </BannerWrapper>
    </Parallax>
  )
}

export default Banner