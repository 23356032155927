import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const RetreatsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 15vh;
`
export const Retreat = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.space.md};
  color: ${({ full }) => (full ? '#b4c2cb' : '#e0b092')};
  border: ${({ border }) => (border ? 'solid 1px #e0b092' : 'none')};
  border-radius: ${({ border }) => (border ? '0 50px 0 50px' : 'none')};
  gap: ${props => props.theme.space.ms};
  @media screen and (max-width: 500px) {
    width: 100%;
    padding-bottom: 0;
    margin-top: 50px;
  }
`

export const RetreatImage = styled.div`
  width: 100%;
  height: 50vh;
  background: url(${props => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0 50px 0 0;
`
export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const RetreatTitle = styled.div`
  font-family: ${props => props.theme.fonts.serif};;
  font-size: ${props => props.theme.fontSizes.lg};
  align-self: flex-start;

  @media screen and (max-width: 500px) {
    font-size: ${props => props.theme.fontSizes.ml};
    align-self: center;
    margin: 5px 0;
  }
`

export const RetreatPlace = styled.div`
  font-family: ${({ full }) => (full ? "'Baskervville', serif" : "'Parisienne', cursive")};
  font-size: ${props => props.theme.fontSizes.lg};
  align-self: flex-end;
  margin-right: ${props => props.theme.space.md};
  @media screen and (max-width: 500px) {
    font-size: ${props => props.theme.fontSizes.ml};
    align-self: center;
    margin: 5px 0;
  }
`

export const RetreatDesc = styled.div`
  font-family: ${props => props.theme.fonts.serif};;
  font-size: ${props => props.theme.fontSizes.sm};
  align-self: flex-start;
`

export const RetreatButton = styled.div`
  font-family: ${props => props.theme.fonts.serif};;
  font-size: ${props => props.theme.fontSizes.md};
  margin: ${props => props.theme.space.sm} 0;
  height: 50px;
  width: 100%;
  background-color: ${({ full }) => (full ? '#b4c2cb' : '#e0b092')};
  color: white;
  text-align: center;
  line-height: 50px;
  border-radius: 0 0 0 20px;
  cursor: pointer;
  display: ${({ full }) => (full ? 'none' : 'block')};
`

export const TestimonialLink = styled(Link)`
  width: auto;
  background-color: ${props => props.theme.colors.secondary};
  color: white;
  font-size: ${props => props.theme.fontSizes.sm};
  padding: ${props => props.theme.space.sm};
  text-align: center;
  border-radius: 0px 20px 0px 20px;
  font-family: ${props => props.theme.fonts.serif};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const PricingItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 30px;
  min-height: 100px;
  padding: 30px;
  margin: 30px;
`

export const PricingTitle = styled.div`
  font-family: "Righteous";
  font-size: ${props => props.theme.fontSizes.md};
  color: white;
  text-align: center;
`

export const PricingButtom = styled.div`
  background-color: white;
  border-radius: 15px;
  color: ${props => props.theme.colors.primary};
  padding: 10px 20px;
  font-family: ${props => props.theme.fonts.serif};
  margin-top: 20px;
  cursor: pointer;
`

export const PricingOptions = styled.div`
  font-family: "Righteous";
  font-size: ${props => props.theme.fontSizes.md};
  color: ${props => props.theme.colors.secondary};
  opacity: 75%;
  text-align: center;
`