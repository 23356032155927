import React, {useEffect} from 'react'
import packageJson from '../../package.json'
import { useParams } from 'react-router-dom'
import articles from '../data/articles'
import Footer from '../components/Footer';
import { ArticleImg, ArticleParagraph, ArticleTitle, ArticleWrapper } from '../components/Article/ArticlePage';
import Nav from '../components/Nav';

const Article = () => {
  const caching = () => {
    let version = localStorage.getItem('version');
    if (version !== packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });
        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  }

  useEffect(() => {
    // caching();
    window.scrollTo(0, 0)
  }, [])

  const { id } = useParams();
  let content = articles.find(item => item.id === id)
  let interval = Math.floor(content.paragraphs.length / content.images.length)
  let gap = interval - 2
  let count = 0
  let sections = [];

  for (let i = 0; i < content.paragraphs.length; i++) {
      if (i === gap || i === 0) {
        if (content.images[count]) {
          let k = (count % 2 === 0) ? 'left' : 'right';
          sections.push(<ArticleImg bg={content.images[count]} position={k} />);
          gap = gap + interval
          count++
        }
      }
      sections.push(<ArticleParagraph dangerouslySetInnerHTML={{__html: content.paragraphs[i] }}></ArticleParagraph>);
  }

  return (
    <div className='site-wrapper'>
      <Nav isFrontPage={false}/>
      <ArticleTitle bg='/assets/icons/frame-title.png'>{content.title}</ArticleTitle>
      <ArticleWrapper>
        {sections}
      </ArticleWrapper>
      <Footer />
    </div>
  )
}

export default Article