import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next';
import { toggleJourney } from '../../actions/shoppingActions';
import { CloseJourney, JourneyContentWrapper, JourneyImg, JourneyText, JourneyTitle, JourneyWrapper } from './JourneyElements';

const Journey = (props) => {
  return (
    <JourneyWrapper isJourneyOpen={props.isJourneyOpen}>
      <JourneyContentWrapper>
        <CloseJourney onClick={props.toggleJourney} />
        <JourneyText>Je suis une jeune femme pleine de rêves et d'ambitions, qui voit la vie comme une belle aventure. Cependant, cela n'a pas toujours été le cas. </JourneyText>
        <JourneyTitle> Enfance & Adolescence  </JourneyTitle>
        <JourneyText>À l'adolescence, j'avais énormément de « ups & downs » et je voulais changer de pays presque à chaque semaine pour recommencer à « zéro » ou simplement « disparaître » car je n'étais pas capable de gérer mes émotions. À cette époque, je ne savais pas que j'étais une ado hypersensible & empathe, ce que je sais maintenant aujourd'hui. J'avais une vie qu'on peut qualifier de « parfaite », avec maman, papa et un frère de 10 ans mon aîné qui m'ont toujours donné tout l'amour dont j'avais besoin. J'avais beaucoup d'amies, j'étais drôle, charmante et attachante. J'habitais dans une adorable maison avec mes parents et mon chien. Je n'arrivais pas à voir tout ce que j'avais, je voyais toujours le verre à moitié vide. Les chicanes d'amies ou les ruptures amoureuses me mettaient à l'envers pour plusieurs semaines. Je traînais en permanence une boule de tristesse inexplicable et un sentiment de pas être à la hauteur pour personne.</JourneyText>
        <JourneyTitle>Jeune adulte</JourneyTitle>
        <JourneyText>À 17 ans, je me suis mise en couple et les choses ont empirées. J'avais un mode de vie de débauche où je buvais les jeudis, vendredis et samedis à ne plus me rappeler de rien. J'enchaînais les disputes avec mon ancien copain, je mangeais très mal, je ne m'entraînais pas et je consommais du cannabis sur une base quotidienne. Mes émotions étaient « all over the place » et je n'avais pas confiance en moi et mes réelles capacités. La vie n'avait pas de « sens ». J'essayais juste de me rendre au jeudi afin de pouvoir me « peter la face ». </JourneyText>
        <JourneyTitle>Jeune femme en devenir</JourneyTitle>
        <JourneyText>En juin 2016, je me suis séparé, après 6 ans de relation. J'avais 23 ans, je commençais mon BAC en travail social et j'étais dévastée comme si ma vie était finie. J'ai eu besoin de 2 ans avec un suivi psychologique pour m'en remettre. Je n'étais plus une personne à part entière, je me définissais à travers ce que nous étions ensemble. Cette année-là, je ne savais pas que j'allais rencontrer le père de mon enfant, mon conjoint actuel. {"\n\n"} En mars 2020, je suis tombé en arrêt de travail pour dépression majeure alors que j'étais travailleuse sociale depuis 2 ans pour la Protection de la Jeunesse. Deux mois plus tard, je me séparais de mon conjoint actuel pour une période de trois mois. {"\n\n"} <b>C'était mon « breaking point ».</b> {"\n\n"} <b>C'était le moment où ma transformation physique et mentale allait commencer.</b> {"\n\n"}Là tu te demandes sans doute pourquoi je te raconte tout ça ? Simplement pour te montrer que je ne suis pas née avec la mentalité que j'ai maintenant. Je l'ai travaillé d'arrache-pied pour parvenir à cet état mental actuel de calme, de gratitude et de bonheur.</JourneyText>

        <JourneyTitle>Femme accomplie et épanouie</JourneyTitle>
        <JourneyImg bg="/assets/images/aboutme1.jpg" position="right" vertical={true} />
        <JourneyText>C'est donc en mars 2020 que j'ai décidé de prendre ma santé mentale et physique en main. J'ai réussi à perdre 25 lbs en 3 mois, obtenir ma formation d'entraîneur privé, me bourrer le crâne 24h/24 de vidéos et de livres de développement personnel pour finalement atteindre un niveau de plénitude et de bien-être que je n'avais JAMAIS ressentis dans ma vie. J'ai désormais des passions, des objectifs personnels, je suis en forme et surtout, j'ai travaillé tellement fort sur mon mindset, mes traumas, mes mauvaises habitudes/patterns et mes perceptions que la vie à un tout autre goût.</JourneyText>
        <JourneyText>C'est ça que je veux pour <b>TOI</b>. Une transformation <b>durable</b> pour t'amener vers des habitudes qui vont te procurer un sentiment de bien-être physique et mental. Ma mission c'est d'aider le plus de gens possible à gagner confiance en eux, à manifester la vie qu'ils savent au fond qu'ils méritent, à mettre leurs limites avec les autres et avec eux-mêmes, à identifier les relations/choses malsaines à l'entoure d'eux ainsi que leurs propres comportements toxiques, bref, à devenir la version 2.0 qu'ils savent au fond d'eux qu'ils sont. </JourneyText>
        <JourneyText>Prête à entamer cette belle aventure avec moi ?</JourneyText>
        <JourneyImg bg="/assets/images/aboutme2.jpg" position="left" vertical={false} />
        <JourneyTitle style={{marginTop: '150px'}}>Formation & Parcours professionnel</JourneyTitle>
        <JourneyText>
          <ul style={{marginBottom: '50px'}}>
            <li>Baccalauréat en travail social</li>
            <li>Mineure Arts et Sciences : Psychologie et Psychoéducation</li>
            <li>Formation comme entraîneur privé </li>
          </ul>
        </JourneyText>
      </JourneyContentWrapper>
    </JourneyWrapper >
  )
}

const mapStateToProps = (state) => {
  return {
    isJourneyOpen: state.shop.isJourneyOpen
  }
}
const mapDispatchToProps = (dispatch) => {

  return {
    toggleJourney: () => dispatch(toggleJourney())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Journey));