import styled from 'styled-components'
import { BsArrowLeft } from 'react-icons/bs'

export const CheckoutPage = styled.div`
  background-image: linear-gradient(#e0b092, #b4c2cb);
  width: 100%;
  min-height: calc(90vh);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  top: -30px;
  padding-bottom: 50px;
`

export const CheckoutBg = styled.img`
  height: auto;
  width: 40%;
  align-self: center;
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
  opacity: 50%;
  z-index: 11;

  /* @media screen and (max-width: 500px) {
    height: 10vh;
    width: 40%;
    top: 0;
    z-index: 10;
    margin-left: 15%;
    opacity: 100%;
  } */
`

export const BackArrow = styled(BsArrowLeft)`
  position: relative;
  color: white;
  font-size: ${props => props.theme.fontSizes.lg};
  margin: 15px ${props => props.theme.space.sm};
  cursor: pointer;
  z-index: 6;
`

export const CheckoutContentWrapper = styled.div`
  align-self: flex-start;
  position: relative;
  margin-top: 75px;
  width: 40%;
  float: right;
  display: flex;
  flex-direction: column;
  /* background: blue; */
  height: auto;
  @media screen and (max-width: 800px) {
    width: 100%;
    float: none;
    padding: 25px;
    margin-top: 20px;
  }
`

export const CheckoutContentTitle = styled.div`
  color: white;
  font-size: ${props => props.theme.fontSizes.lg};
  font-family: ${props => props.theme.fonts.serif};
  font-weight: bold;
  margin-bottom: 50px;

  @media screen and (max-width: 800px) {
    font-size: ${props => props.theme.fontSizes.md};
  }
`

export const CheckoutItemPrice = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
  font-family: "Righteous", "sans-serif";
  align-self: flex-start;
  margin-left: 15px;
  @media screen and (max-width: 800px) {
    font-size: ${props => props.theme.fontSizes.sm};
  }
`

export const CheckoutPendingWrapped = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

export const CheckoutConfirmation = styled.div`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes.md};
  color: white;
  margin: 20px 0;
  font-family: ${props => props.theme.fonts.serif};
`