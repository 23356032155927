import React from 'react'
import { TestimonialsSectionWrapper, TestimonialsWrapper, Testimony, TestimonySub, TestimonyWrapper } from './TestimonialsElements'
import testimonials from '../../data/testimonials'

const Testimonials = () => {

  let testi = testimonials.map((item) => {
    let align = (item.id % 2 === 0) ? 'flex-start' : 'flex-end';
    return (
      <TestimonyWrapper align={align} key={item.id}>
        <Testimony>« {item.text} »</Testimony>
        <TestimonySub>-{item.author}</TestimonySub>
      </TestimonyWrapper>
    )
  })

  return (
    <TestimonialsSectionWrapper>
      <TestimonialsWrapper>
        {testi}
      </TestimonialsWrapper>
    </TestimonialsSectionWrapper>
  )
}

export default Testimonials