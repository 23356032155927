import React from 'react'
import { Links, LinksWrapper } from './NavLinkElements'

const NavLink = () => {
  return (
    <LinksWrapper>
      <Links 
        className={(navData) => (navData.isActive ? 'selected' : '')}        
        to='/retreats'>Retraites</Links>
      <Links
        className={(navData) => (navData.isActive ? 'selected' : '')}  
        to='/classes'>e-Cours</Links>
      <Links
        className={(navData) => (navData.isActive ? 'selected' : '')}  
        to='/exercices'>Exercices</Links>
    </LinksWrapper>
  )
}

export default NavLink