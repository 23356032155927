export const articles = [
  {
    id: 'sphere',
    cover: '/assets/images/3.jpeg',
    title: "Sur quelle sphère de ta vie je peux t'aider ?",
    description: "Identifier tes fausses croyances et les déconstruire. Voir comment l'opinion des autres t'affectent et t'aider à t'en départir. Identifier tes blessures d'âme et travailler pour les guérir. T'aider à atteindre tes objectifs (personnel ou professionnel). Gagner confiance en toi. Apprendre à identifier tes limites pour être ensuite capable de le mettre aux autres. Défaire certaines comportements toxiques que tu traînes depuis l'enfance.",
    readmore: false,
    paragraphs: [
      "Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Maecenas faucibus mollis interdum. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod semper.",
      "Integer posuere erat a ante Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Donec sed odio dui. Donec sed odio dui.Sed posuere consectetur est at lobortis. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.venenatis dapibus posuere velit aliquet. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Maecenas faucibus mollis interdum. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod semper.",
      "Integer posuere erat a ante Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Donec sed odio dui. Donec sed odio dui.Sed posuere consectetur est at lobortis. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Cras mattis consectetur purus sit amet fermentum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.venenatis dapibus posuere velit aliquet. Sed posuere consectetur est at lobortis. Maecenas faucibus mollis interdum. Maecenas faucibus mollis interdum. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vestibulum id ligula porta felis euismod semper.",
      "Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Donec id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis ornare vel eu leo. Donec ullamcorper nulla non metus auctor fringilla.",
      "Nullam quis risus eget urna mollis ornare vel eu leo. Etiam porta sem malesuada magna mollis euismod. Donec id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus eget urna mollis ornare vel eu leo. Donec ullamcorper nulla non metus auctor fringilla.",
      "Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Curabitur blandit tempus porttitor. Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper.",
      "Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Donec sed odio dui. Donec id elit non mi porta gravida at eget metus. Curabitur blandit tempus porttitor. Sed posuere consectetur est at lobortis. Vestibulum id ligula porta felis euismod semper."
    ],
    images: [
      '/assets/images/9.jpeg',
      '/assets/images/10.jpeg',
      '/assets/images/8.jpeg',
    ]
  },
  {
    id: 'retraite',
    title: "Qu'est-ce que les retraites peuvent t'amener ?",
    cover: '/assets/images/9.jpeg',
    description: "Confiance en toi. Calme et repos. Déconstruire certaines croyances. Te donner un boost pour ton année. Comprendre tes comportements et schémas de penser. Apprendre à t'affirmer. Te défaire de l'opinion des autres. Te laisser briller en étant toi-même. Rencontrer des femmes merveilleuses. ",
    readmore: "Consultes les témoignages de clientes satisfaites",
    paragraphs: [
      "<b>Qu'en as-tu retiré comme positif?</b>",
      "Des rencontres avec des personnes formidables, un sentiment de sécurité provenant du respect et du non jugement de l'ensemble des participantes et de la coach. Cette ambiance saine et positive crée la possibilité de baisser nos barrières et certains mécanismes de défense présents de manière inconsciente(masque). Me sentant en sécurité, j'ai été en mesure de confronter et aborder mes peurs et des sujets plus fragiles. Cette confrontation permet réellement de cheminer dans son processus de développement personnel et d'évoluer en tant qu'humain. De par les séances de développement personnel, des échanges avec le coach et les autres participantes et des méditations guidées, j'ai réellement compris certains aspects de mes comportements et de mes « patterns ». De plus, on explore et on nous propose des moyens concrets pour se défaire de nos fausses croyances. Cela m'a également permis d'explorer mes forces, de les accepter et de les mettre de l'avant. Les partages d'histoire de vie des participantes m'a permis d'en apprendre davantage sur moi-même tout en me sensibilisant à diverses réalités qui m'étaient moins familières. Le contexte de proximité de cette retraite favorise le sentiment d'appartenance au groupe, ce qui est une part significative de l'expérience.",
      "<b>Recommanderais-tu cette retraite?</b>",
      "Je recommande cette retraite pour toutes celles qui souhaite en apprendre davantage sur elle-même, qui sont prête à s'ouvrir à leur monde intérieur et qui souhaite cheminer.",
      "  -  <i>Chloé A.</i>",
      "--------------------------------------------------------------------------------",
      "<b>Qu'en as-tu retiré comme positif?</b>",
      "Trop de choses! D'abord, j'ai appris beaucoup sur ma personne et ma vulnérabilité. J'ai compris que je ne me connaissais pas autant que je le pensais et que j'avais des choses à travailler. Ça m'a donné envie de poursuivre ce travail sur moi-même. J'ai aussi rencontré de magnifiques personnes toutes différentes les unes des autres. Chacune m'a apporté quelque chose de beau. D'ailleurs, j'ai lu le livre des blessures et woww j'ai compris des choses. 😂 C'est fou !!! Je me reconnaissais trop. ",
      "<b>Recommanderais-tu cette retraite?</b>",
      "Telleeeement. D'ailleurs, ma mère aimerait s'inscrire à la prochaine hahaha. Ça lui a donné le goût! Je pense que tout le monde devrait faire au moins une retraite dans sa vie. La sienne était parfaite. Même si c'est dure de comparer quand on en a vécu qu'une seule, je crois que je recommanderais celle-ci puisque, selon ce que j'ai entendu, il y a certaines retraites vraiment plus ''sévères'' (pas d'alcool, planning plus intense, etc.). J'ai aimé que la retraite soit humaine et légère, sans pression. ",
      "  -  <i>Kathya R. </i>",
      "--------------------------------------------------------------------------------",
      "<b>Qu'en as-tu retiré comme positif?</b>",
      "J'ai fait de belles rencontres, je suis sortie de ma zone de confort, les ateliers de développement personnel m'ont appris à mieux me connaître et me comprendre comme personne. J'en aurais pris encore et encore !!",
      "<b>Recommanderais-tu cette retraite?</b>",
      "Oui, pour les belles rencontres, l'emplacement génial et les apprentissages que nous avons fait tout au long de la semaine :) ",
      "  -  <i>Mélissa D. </i>",
      "--------------------------------------------------------------------------------",
      "<br/><b>Qu'en as-tu retiré comme positif?</b>",
      "Ce que je retiens, c'est qu'être vulnérable et de vivre ses émotions c'est OK, c'est correct, c'est humain et que ça ne fait pas de vous quelqu'un de moins fort pour autant, tout au contraire. J'ai changé ma perception sur la force, j'ai rencontré ces femmes fortes, avec une belle énergie qui m'ont apporté une autre vision des autres et de moi-même et mon aider à ouvrir mon cœur.",
      "<b>Recommanderais-tu cette retraite?</b>",
      "À 1000%! Un cadeau à s'offrir.",
      "  -  <i>Mélissa G. </i>",
    ],
    images: [
      '/assets/images/retraite1.jpg',
      '/assets/images/retraite2.jpg',
      '/assets/images/retraite3.jpg',
      '/assets/images/retraite4.jpg',
      '/assets/images/retraite5.jpg',
    ]
  },
]

export default articles;