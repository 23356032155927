export const testimonials = [
  {
    id: '1',
    text: "Audrey est une femme ambitieuse, courageuse de qui on peut grandement s'inspirer de par son parcours de vie qui a été semée d'embuches. Elle a su se montrer transparente, honnête, voire même vulnérable, ce qui l'humanise davantage. En aucun temps elle a utilisé son rôle de coach pour se placer en position de supériorité face aux participantes. Il est facile de voir qu'Audrey a mis en pratique plusieurs stratégies afin de se développer personnellement et qu'elle souhaite partager et aider les autres à créer leur propre chemin de croissance personnelle, sans imposer ses croyances.",
    author: 'Chloé A.'
  },
  {
    id: '2',
    text: "Une magnifique personne!!! Je la connaissais déjà, mais pas beaucoup. Elle est la belle personne que je pensais qu'elle était sans même la connaître. Elle ne juge pas, elle est ouverte d'esprit et tellement à l'écoute. Elle est parfaite comme coach! Elle est dans son élément et un bel exemple à suivre.",
    author: 'Kathya R.',
  },
  {
    id: '3',
    text: "Elle est à l'écoute, vraiment attentionnée et pleine d'empathie. Elle sait comment créer des liens avec les participantes. Elle est juste et égale avec chacune et nous fait sentir bien dans l'échange. Elle nous donne le goût de s'ouvrir malgré que nous sommes devant des inconnues, de par son écoute et ouverture. Elle nous amène de bonnes pistes de réflexion et moyens pour travailler sur soi-même!",
    author: 'Mélissa D.',
  },
  {
    id: '4',
    text: "Durant les ateliers, elle nous laisse participer lors de l'intervention auprès des autres et nous fait sentir que nos interactions et conseils sont tout aussi valide que les siennes. Vraiment un gros Wow!",
    author: 'Mélissa G.',
  }
]

export default testimonials;