import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages'
import Article from './pages/article';
import Classes from './pages/classes';
import Exercices from './pages/exercices';
import Retreats from './pages/retreats';
import './App.css';
import Checkout from './pages/checkout';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <ToastContainer
        limit={1}
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/retreats' element={<Retreats />} exact />
        <Route path='/classes' element={<Classes />} exact />
        <Route path='/exercices' element={<Exercices />} exact />
        <Route path="/article/:id" element={<Article />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
    </Router>
  );
}

export default App;
